<template>
  <el-dialog
    :visible="modalShow"
    class="applicationInfoBox"
    :show-close="false"
    width="656px"
    height="765px"
    center
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <h3 slot="title" class="title fontSize20 margin-t20">
      问题申诉
    </h3>
    <svg class="closeIcon" aria-hidden="true" @click="closeModal()">
      <use xlink:href="#iconguanbi" />
    </svg>
    <el-form
      class="main"
      ref="formData"
      :rules="rules"
      :model="formData">
      <el-row>
        <p class="subTitle"><span>*&nbsp;</span>请选择您申诉的问题类型，最多3个</p>
        <el-form-item prop="type">
          <el-checkbox-group v-model="formData.type" :max="3" class="checkbox" >
            <el-checkbox
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.id"
              :value="item.id"
              :data-value="item.id"
              class="item"
              name="type"
              >{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-row>
      <el-row>
        <p class="subTitle"><span>*&nbsp;</span>请选择您申诉的厂商</p>
        <el-form-item prop="memberId"> 
          <el-checkbox-group v-model="formData.memberId" class="radioBox" >
            <el-checkbox
              v-for="(item, index) in members"
              :key="index"
              :label="item.id"
              :data-value="item.id"
              :value="item.id"
              name="memberId"
              >{{ item.memberName }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-row>
      <el-row>
        <p class="subTitle"><span>*&nbsp;</span>请填写您申诉的问题</p>
        <el-form-item prop="appealDesc">
          <el-input 
            type="textarea"
            class="desc"
            v-model="formData.appealDesc"
            maxlength="500"
            resize="none"
            @input="descInput"
            placeholder="请填写您申诉的问题，最多500字"
          ></el-input>
          <p class="desc-msg">还可输入<span class="number">{{ enter }}</span>字</p>
          <div class="line"></div>
        </el-form-item>
      </el-row>
      <el-row>
        <p class="subTitle"><span>*&nbsp;</span>请上传图片</p>
        <el-form-item prop="appealPicUrl" class="apkIconBox">
          <div class="uploadBox">
            <el-upload
            accept="image/png, image/jpeg"
            :action="configBaseURL + '/api/upload'"
            :data="{ type: 'identifyIcon' }"
            list-type="picture-card"
            :on-success="handleUpload"
            :on-change="handleIntroduceUploadHide"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :class="{ hide: ruleForm.hideUpload }"
            >
            <i class="el-icon-plus avatar-uploader-icon" :class="{ hide: ruleForm.hideUploa }"></i>
          </el-upload>
          </div>        
          <p class="msg">您可以上传图片，最多5张，图片格式jpg、png，每张图片不超过1MB。</p>
          <div class="line"></div>
        </el-form-item>
      </el-row>
      <el-row :gutter="20" type="flex" justify="space-around">
        <el-button class="btn" @click="closeModal()">取消</el-button>
        <el-button class="btn" @click="onSubmit('formData')" :disabled="disabled">提交</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { singleGoldIdentificationSever } from "@/common/api-config";
export default {
   props: {
    modalShow: Boolean,
  },
  data() {
    return {
      ruleForm:{
        hideUpload: false,
        limitCount: 5,
      },
      //显示dialog
      disabled:false,
      typeList: [],
      //数据
      formData: {
        type: [],
        memberId: [],
        appealDesc: "",
        appealPicUrl: [],
      },
      rules: {
        type: [
           {type: 'array',required: true, message: '请至少选择一个申诉问题', trigger: 'change' }
        ],
        memberId: [
          {type: 'array',required: true, message: '请至少选择一个申诉厂商', trigger: 'change' }
        ],
        appealDesc: [
          { required: true, message: "请填写申诉问题", trigger: "blur" },
        ],
        appealPicUrl: [
          { type: 'array',required: true, message: "请上传图片", trigger: "blur" },
        ],
      },
      enter: 500,
    };
  },
  computed: {
    members() {
      //厂商list
      return this.$store.getters["membersStore/members"];
    },
  },
  mounted() {
    this.getTypeList();
  },
  methods: {
    handleIntroduceUploadHide(file, fileList) {
      
    },

    // 关闭弹框
    closeModal() {
      this.$emit("closeModal", false);
    },
    //计算输入框还能输入多少字
    descInput() {
      var txtVal = 500 - this.formData.appealDesc.length;
      this.enter = txtVal;
    },
    
    //获取问题类型列表
    getTypeList() {
      singleGoldIdentificationSever.getTypeList().then((res) => {
        if (res.code == 0) {
          this.typeList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
 
    //上传前校验
    beforeUpload(file) {
      if(this.formData.appealPicUrl.length>4) {
        this.$message.error(`只能上传5张图片!`);
        return false
      }
      const isSize = file.size / 1024 / 1024 < 1;
      if (!isSize) {
        this.$message.error(`上传文件大小不能超过 1MB!`);
      }
      return isSize;
    },

    //上传成功
    handleUpload(res, file, filelist) {
      
      if (res.code == 0) {
        if (this.formData.appealPicUrl.indexOf(res.data.link) === -1) {
          this.formData.appealPicUrl.push(res.data.link);
          this.ruleForm.hideUpload = this.formData.appealPicUrl.length >=this.ruleForm.limitCount;
        }
        if( this.formData.appealPicUrl.length>0){
          this.$refs.formData.clearValidate('appealPicUrl') //清除图片校验文字
        }  
      } else {
        this.$message.error(res.message);
      }
    },

    //删除图片
    handleRemove(file){
      if(file.response){
        const filePic = file.response.data.link;
        const i = this.formData.appealPicUrl.findIndex(x => x === filePic);
        this.formData.appealPicUrl.splice(i, 1);  
        this.ruleForm.hideUpload = this.formData.appealPicUrl.length>= this.ruleForm.limitCount;
      }  
    },
    handlePictureCardPreview(file){
      //  console.log(file)
    },
    //表单提交
    onSubmit(formName) {
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabled = true;
          singleGoldIdentificationSever
            .subProblemApply({
              apkPackage: this.$route.query.apkPackage,
              type: this.formData.type.toString(),
              memberId: this.formData.memberId.toString(),
              appealDesc: this.formData.appealDesc,
              appealPicUrl: this.formData.appealPicUrl.toString(),
            })
            .then((res) => {
              if (res.code == 0) {
                this.closeModal();
                this.disabled = false;
                this.$msgBox
                  .alertMessageBox({
                    title: "提交成功！",
                    content:
                      "您申诉的问题的已成功提交，请耐心等待官网工作人员的反馈。谢谢！",
                    btnText: "好的",
                  })
                  .then(() => {});
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.applicationInfoBox {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
  // /deep/ .el-progress-bar {
  //   padding-right: 16px;
  //   margin-right: -25px;
  // }
  // /deep/ .el-form-item__label {
  //   font-weight: bold;
  //   color: #333333;
  // }
  /deep/.hide .el-upload--picture-card {
    display: none;
  }

  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 9;
  }
  .title {
    position: relative;
    font-size: 18px;
    color: rgba(18, 18, 18, 1);
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 73px;
      height: 6px;
      background: rgba(222, 208, 148, 1);
      border-radius: 3px;
      margin-bottom: 10px;
    }
  }
  .main {
    padding-left: 35px;
    padding-right: 25px;
    .subTitle {
      color: #333333;
      font-size: 16px;
      margin-bottom: 10px;
      span {
        color: red;
        font-size: 20px;
        align-items: center;
      }
    }
    .line{
      width:497px;
      height:0px;
      border-top:1px #DFDFDF dashed;
    }
    .radioBox {
      display: flex;
      justify-content: space-between;
      padding-right: 70px;
    }
    .checkbox {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 95px;
        margin-right: 40px;
      }
    }
    /deep/ .el-form-item__content {
      margin: 5px 15px 0;
    }
    .desc {
      width: 495px;
      height: 135px;
      /deep/ .el-textarea__inner {
        height: 135px;
      }
    }
    .desc-msg {
      color: #666666;
      .number{
        color:#CFAF27;
      }
    }
    .apkIconBox {
      .uploadBox{
        height: 82px;
        overflow: hidden;
        /deep/ .el-upload {
        background: #fff !important;    
        border-radius: 0% !important;
        border:none;
        width: 80px;
        height: 80px;
        position: relative;
        .avatar-uploader-icon{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          left: 0;
          margin: auto;
          line-height: 80px;
          border: 1px solid #dfdfdf !important;
        }
      }
      .msg {
        color: #666666;
        width: 443px;
      }
       /deep/ .el-upload-list__item,.is-success{
        width: 80px !important;
        height: 80px !important;
      }

      }
      
    }
    .btn {
      width: 230px;
      background: #212125;
      color: #fff;
    }
  }
}
</style>
