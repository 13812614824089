
<template>
  <el-dialog
    :visible="dialogShow"
    width="650px"
    class="certificateDialog"
    :show-close="false">
    <img class="certificate" :src="certUrl" alt="">
    <span slot="footer" class="dialog-footer">
        <el-button  type="primary"  @click="downloadCodeImg(certUrl)">下载</el-button>
        <el-button @click="closeModal()">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default{
  props:{
    dialogShow: Boolean,
    certUrl:String
  },
  data(){
    return{

    }
  },
  methods:{
    downloadCodeImg(url){
      window.location.href = configBaseURL+'/api/show/downloadImage?image='+url;
    },
     // 关闭弹框
    closeModal() {
      this.$emit("closeDialogShow", false);
    },
  }
}
</script>
<style scoped lang="scss">
    /deep/.el-dialog {
    background-color: rgba(0, 0, 0,0);
    border: none;
    box-shadow: none;
    .certificate{
      width: 600px;
      height: 800px;
    }
    .el-dialog__footer{
      text-align: center;
    }
  }
</style>

