<template>
  <div class="pageBox">
    <div class="content">
      <!-- <h3 class="title">
        <p><strong @click="goBack()" style="cursor: pointer;">应用管理</strong>&emsp;<span style="font-size:24px;color:#DED094">&gt;</span>&emsp;<span style="color:#fff">{{this.appDetail.apkName}}</span></p>
      </h3> -->
      <div class="applicationBox">
        <div class="searchBox">
          <div class="appInfo">
            <div class="imgBox">
              <img :src="appDetail.apkIcon" alt="" />
            </div>
            <div>
              <h1>{{appDetail.apkName}}</h1>
              <p>应用包名<span>{{appDetail.apkPackage}}</span></p>
              <p v-if="appDetail.certStatus==1" @click="lookcCrtificate()" class="lookBox cursor"><svg class="look cursor" aria-hidden="true"><use xlink:href="#iconzhengshu-copy" /></svg>查看金标证书</p>
            </div>
          </div>

          <div class="search">
            <el-input class="searchBtn" placeholder="输入版本号快速搜索" clearable  v-model.trim="versionCode" @keyup.enter.native="search()">
              <el-button class="fontSize18 searchIcon" slot="append" icon="el-icon-search" @click="search()" ></el-button>
            </el-input>
            <div class="authenticationBtn" @click="onProblemAppeal()">问题申诉</div>
          </div>
        </div>

        <el-table
         class="tableBox"
        stripe
        :data="tableData.list"
        style="width: 100%"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}">
          <el-table-column type="index" label="序号" width="100" ></el-table-column>
          <el-table-column  label="版本">
            <template slot-scope="scope">
              <p>{{scope.row.versionName}}({{ scope.row.versionCode}})</p>
            </template>
          </el-table-column>
          <el-table-column  label="提交时间">
            <template slot-scope="scope">
              <p>{{scope.row.modifyTime| parseTime()}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="金标认证">
            <template slot-scope="scope">
              <p>{{ scope.row.state | goldIdentificationState() }}</p>
            </template>
          </el-table-column>
          <el-table-column v-if="stateList.compatibleStandard" prop="compatible" label="兼容标准">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 2 || scope.row.state == 3">{{ scope.row.compatible | standardReviewStatus() }}</p>
              <p v-else>/</p>
            </template>
          </el-table-column> 
          <el-table-column v-if="stateList.stableStandard" prop="stable" label="稳定标准">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 2 || scope.row.state == 3">{{ scope.row.stable | standardReviewStatus() }}</p>
              <p v-else>/</p>
            </template>
          </el-table-column>        
          <el-table-column v-if="stateList.securityStandard" prop="security" label="安全标准">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 2 || scope.row.state == 3">{{ scope.row.security | standardReviewStatus() }}</p>
              <p v-else>/</p>
            </template>
          </el-table-column>
          <el-table-column v-if="stateList.consumptionStandard" prop="consumption" label="功耗标准">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 2 || scope.row.state == 3">{{ scope.row.consumption | standardReviewStatus() }}</p>
              <p v-else>/</p>
            </template>
          </el-table-column>
          <el-table-column v-if="stateList.performanceStandard" prop="performance" label="性能标准">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 2 || scope.row.state == 3">{{ scope.row.performance | standardReviewStatus() }}</p>
              <p v-else>/</p>
            </template>
          </el-table-column>
          <el-table-column v-if="stateList.largeStandard" prop="large" label="大屏标准">
            <template slot-scope="scope">
              <p v-if="scope.row.state == 2 || scope.row.state == 3">{{ scope.row.large | standardReviewStatus() }}</p>
              <p v-else>/</p> 
            </template>
          </el-table-column>
          <el-table-column label="隐私标准">
            <template slot-scope="scope">
            <!-- 认证完成时间在2024年6月17日12点之后显示认证通过，否则不显示  -->
              <p>{{ scope.row.reviewTime >= privacyTime  ? '认证通过' : '/' }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column v-for="(item, index) in tableData.memberName" :key="index" :label="item">
            <template slot-scope="scope">
              <template v-for="(item2 ) in scope.row.memberReview">
                <p v-if="item == item2.memberName" :key="item2.memberCode">{{ item2.reviewState | certificationResultsState()}}</p>
              </template>
            </template>
          </el-table-column> -->
          <el-table-column prop="state" label="认证报告">
            <template slot-scope="scope" >
              <template v-if="scope.row.viewReport==1">
                <template  v-for="(item) in scope.row.memberReview">
                  <p v-if="item.reportUrl!=''&& scope.row.state != 1 && scope.row.state != 4 && scope.row.state != 5 " :key="item.memberCode">
                    <a target="_blank" class="blue report"
                      :href="item.reportUrl">
                      {{item.memberName}}认证报告
                    </a>
                  </p>
                </template>
              </template>
              <template v-if="scope.row.viewReport==2">
                <a v-if=" scope.row.state != 1 && scope.row.state != 4 && scope.row.state != 5 " class="blue pointer" @click="goLookReport(scope.row)">查看报告</a>
              </template>
            </template>      
          </el-table-column>
          <el-table-column  label="提示" width="200">
            <template slot-scope="scope">
              <p v-if="scope.row.reason==''|| scope.row.state == 1">/</p>
              <p v-else>{{scope.row.reason}}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <a v-if="scope.row.isSupportRetest == 1&&!onAdainBtn" class="blue pointer" @click="onAdain(scope.row)">重新认证</a>
            </template>  
            <!-- v-if="scope.row.isSupportRetest == 1" -->
          </el-table-column>
        </el-table> 
        <div class="margin-t30 text-right">
          <el-pagination
            class="paginationBox"
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page.sync="page"
            :page-sizes="[10,20,50,100]"
            :page-size="perPage"
            :total="total"
            layout="total, prev, pager, next, sizes">
          </el-pagination>
        </div>
      </div>
    </div>
    <ProblemAppeal v-if="isShowlModal" :modalShow="isShowlModal" @closeModal="closeModal"></ProblemAppeal>
    <CertificateDialog v-if="dialogVisible" :dialogShow="dialogVisible" :certUrl='appDetail.certUrl' @closeDialogShow="closeDialogShow"></CertificateDialog>
  </div>
</template>

<script>
import ProblemAppeal from "./template/problemAppeal.vue";
import CertificateDialog from "./template/certificateDialog.vue"
import { singleGoldIdentificationSever } from "@/common/api-config";
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.breadcrumbName, path: to.fullPath} );
    next()
  },
  components: { ProblemAppeal,CertificateDialog },
  data() {
    return {
      dialogVisible:false,
      onAdainBtn:false,
      isShowlModal:false,
      apkPackage:this.$route.query.apkPackage,
      versionCode:"",
      page: 1,
      perPage: 10,
      total: 5,
      tableData: [], //应用版本list
      appDetail:{
        apkName:"",
        apkPackage:"",
        apkIcon:"",
        id:"",
        apkReviewState:""
      },
      stateList:{},
      identifyDetail:{compatible:1,consumption:1,stable:1,performance:1,security:1},
      privacyTime: new Date('2024-06-17 12:00:00').getTime() //隐私标准是否显示判断标准
    };
  },
  created(){
    this.queryDetailList()//应用版本列表
    this.queryIdentifyLastDetail()//获取应用详情
    this.queryShowStandardByApkPackage();
  },
  mounted(){
  },
  methods:{
    //获取应用版本列表
    queryDetailList(){
      singleGoldIdentificationSever
      .queryDetailList({
        apkPackage:this.apkPackage,
        versionCode: this.versionCode,
        page: this.page,
        perPage: this.perPage,
      }).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    queryShowStandardByApkPackage(){
      singleGoldIdentificationSever.queryShowStandardByApkPackage({apkPackage:this.apkPackage}).then(res => {
        if (res.code == 0) {
          this.stateList=res.data
        } else {      
          this.$message.error(res.message);
        }
      });
    },

    //获取应用信息
    queryIdentifyLastDetail(){
      singleGoldIdentificationSever.queryIdentifyLastDetail({
        apkPackage:this.apkPackage,
      }).then(res=>{
        if (res.code == 0) {
          this.appDetail=res.data
        }
      })
    },
    //重新认证
    onAdain(item){
      this.onAdainBtn = true;
      singleGoldIdentificationSever.userRetestApply({
        apkPackage:item.apkPackage,
        versionCode:item.versionCode,
      }).then(res=>{
        if (res.code == 0) {
          this.$msgBox
            .alertMessageBox({
                title: "认证提交成功！",
                content:"请耐心等待官方认证，我们将会通过邮件通知您认证结果",
                btnText: "知道了",
            })
            .then(
              this.queryDetailList(),
              this.onAdainBtn = false,
            );
        }else{
          this.$msgBox
          .alertMessageBox({
              content:res.message,
              btnText: "好的",
          })
          .then(this.onAdainBtn = false,);
        }
      })
    },
    //查看报告
    goLookReport(item){
      this.$router.push({
        path:'attestReportList',
        query:{
          id:item.id,
          breadcrumbName:'认证报告'
        }
      });
    },
    //搜索
    search() {
      this.page = 1;
      this.queryDetailList();
    },
    //每页多少条
    sizeChange(val) {
      this.perPage = val;
      this.queryDetailList();
    },
    //翻页
    currentChange(val) {
      this.page = val;
      this.queryDetailList();
    },
    onProblemAppeal() { 
      this.isShowlModal = true  
    },
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    },

    lookcCrtificate(){
      this.dialogVisible = true  
    },
    // 关闭modal
    closeDialogShow(val) {
      this.dialogVisible = val;
    },
    goBack(){
      this.$router.back(-1);
    }
  },
  beforeRouteLeave(to, from, next){
    //返回上一页：  缓存上一页  不缓存当前页(释放缓存，方便数据重新加载) 
    if(to.name === 'list') {
      to.meta.keepAlive = true;
      from.meta.keepAlive = false
    }
    //去下一页 缓存当前 在去下一页
    if(to.name === 'attestReportList') {
      from.meta.keepAlive = true;
    }
    next()
  }
};
</script>

<style scoped lang="scss">
.pageBox {
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .pointer{
    cursor: pointer;
  }

  .content {
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    overflow: hidden;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 49px;
      font-size: 24px; 
      line-height: 100%;
      color: rgba(201, 193, 153, 1);
      background: url(../../../assets/img/console/titleBg.jpg) no-repeat #28252c;
      background-position: 70% 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 33px;
        width: 5px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #ded094;
      }
    }
    .applicationBox {
      padding: 24px 40px 30px 41px;
      min-height: 700px;
      .searchBox {
        display: flex;
          justify-content: space-between;
        .appInfo {
          display: flex;
          .imgBox {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            margin-right: 19px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .lookBox{
            display: flex;
            align-items: center;
            .look{
              margin-right: 5px;
              width: 25px;
              height: 35px;
            }
          }
          
          h1{           
            font-size: 24px;
            color: #000000;
            font-weight: bold;
          }
          p{
            font-size: 14px;
            margin-top: 10px;
            color: #333;
            font-weight: bold;
              span{
              color: #666;
              margin-left: 5px;
              font-weight: normal;
            }
          }        
        }
        .search{
          display: flex;
          width: 50%;    
          .searchBtn {
            width: 80%;
            height: 48px;
            &:hover{
              opacity: 0.7;
            }
            /deep/ .el-input__inner {
              height: 48px;
            }
            .searchIcon {
              background: #fff;
            }
          }
          .authenticationBtn {
            margin-left: 20px;
            width: 186px;
            height: 49px;
            background: #28262C;
            border-radius: 10px;
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            color: #DED094;
            cursor: pointer;
            &:hover{
              background:#d8b656b8;
              color: #000;
            }
          }
        }       
      }
      .tableBox{
        margin-top: 20px;
        
        .report{
          cursor: pointer;
          &:hover{
            text-decoration:underline;
          }
        }
      }
    }
  }
}
</style>
